import React from 'react';
import { FormikErrors } from 'formik';
import { scrollToFieldError } from 'src/util/formikUtil';

interface ScrollToErrorHandler {
  submitCount: number;
  isSubmitting: boolean;
  errors: FormikErrors<{}>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues: { [key: string]: any };
  shouldShowFormValidationError: boolean;
  setShouldShowFormValidationError: (shouldShow: boolean) => void;
}

const ScrollToErrorHandler: React.FC<ScrollToErrorHandler> = ({
  shouldShowFormValidationError,
  setShouldShowFormValidationError,
  submitCount,
  isSubmitting,
  errors,
  initialValues,
}: ScrollToErrorHandler) => {
  const isFormInvalidAfterSubmit =
    submitCount > 0 && !isSubmitting && Object.keys(errors).length > 0;

  React.useEffect(() => {
    if (isFormInvalidAfterSubmit) {
      const keys = Object.keys(initialValues);
      scrollToFieldError(keys, errors);

      if (!shouldShowFormValidationError) {
        setShouldShowFormValidationError(true);
      }
    }
  }, [shouldShowFormValidationError, submitCount, isSubmitting]);

  return <></>;
};

export default ScrollToErrorHandler;

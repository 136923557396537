import React, { FC, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.fox.colors.neutral.s0.hex,
    border: `1px solid ${theme.fox.colors.neutral.s500.hex}`,
    borderRadius: '2px',
    height: '24px',
    width: '24px',
  },
}));

interface Props {
  children?: ReactNode;
}

const CheckboxIcon: FC<Props> = (props: Props) => {
  const classes = useStyles({});
  return <Box className={classes.root}>{props.children}</Box>;
};

export default CheckboxIcon;

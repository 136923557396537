import React from 'react';
import { Field, useFormikContext } from 'formik';
import _find from 'lodash/find';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@fox/components/Autocomplete';
import { isWindows } from 'src/util/navigator';
import withStore from '@components/Store/withStore/withStore';
import { CountryLists } from 'server/routes/api/countryLists';
import validationMessages from '../../i18n/validationMessages';
import Alert from 'src/fox/components/Alert';
import { notifyFieldError } from 'src/util/analytics/googleTagManager';
import { ukBusinessNonSupportCountry } from './countryList';

const validateCountry = (
  country,
  nonSupportedCountries,
  disableValidation
): string => {
  if (disableValidation && !country) return;
  if (!country) return validationMessages.country.fieldRequired;

  const businessCountry = sessionStorage.getItem('businessCountry');

  const countryList =
    businessCountry === 'GB'
      ? ukBusinessNonSupportCountry
      : nonSupportedCountries;
  const invalidCountry = _find(countryList, { value: country });

  if (invalidCountry) {
    if (businessCountry === 'GB') {
      sessionStorage.setItem('validateCountryResult', 'true');
      return;
    }
    return validationMessages.country.invalid(invalidCountry.label);
  }
  sessionStorage.removeItem('validateCountryResult');
};

// isoCode must be: ISO 3166-1 alpha-2
// ⚠️ String.fromCodePoint has no support for IE 11
const countryToFlag = (isoCode: string): string => {
  if (typeof isoCode !== 'string') return '';
  return typeof String.fromCodePoint !== 'undefined' && !isWindows()
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : '';
};

export const getCountries = async (): Promise<CountryLists> => {
  const { sortedCountries, nonSupportedCountries } = await (
    await fetch('/api/countryLists')
  ).json();

  return {
    sortedCountries,
    nonSupportedCountries,
  };
};

interface CountrySelectProps {
  name: string;
  trackingId: string;
  label: string;
  value?: string;
  setShowSystemErrorMessage?: (showSystemErrorMessage: boolean) => void;
  disableAddressValidation?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
interface FormValues {
  country: string;
}

// TODO remove this default list once fetch country list from getInitialProps
// This list is based on siteRegion cookie possible scenarios
const defaultCountryList = [
  {
    label: 'Australia',
    value: 'AU',
  },
  { label: 'Canada', value: 'CA' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Singapore', value: 'SG' },
  { label: 'United Kingdom', value: 'GB' },
  {
    label: 'United States of America',
    value: 'US',
  },
  {
    label: 'Ireland',
    value: 'IE',
  },
];

function CountrySelect({
  name,
  trackingId,
  label,
  value,
  setShowSystemErrorMessage,
  disableAddressValidation,
  onChange,
}: CountrySelectProps): JSX.Element {
  const [countries, setCountries] = React.useState(defaultCountryList);
  const [nonSupportedCountries, setNonSupportedCountries] =
    React.useState(null);
  const {
    values: { country },
  } = useFormikContext<FormValues>();

  React.useEffect(() => {
    if (onChange) {
      onChange({
        target: { name: 'addressDetails.Country', value },
      } as React.ChangeEvent<HTMLInputElement>);
    }
    if (!nonSupportedCountries) return;

    const nonSupportedCountry = _find(nonSupportedCountries, { value });

    if (nonSupportedCountry) {
      notifyFieldError({ field: 'text-country', value });
    }
  }, [value]);

  React.useEffect(() => {
    (async function () {
      try {
        const {
          sortedCountries,
          nonSupportedCountries,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await getCountries()) as any;
        setCountries(sortedCountries);
        setNonSupportedCountries(nonSupportedCountries);
      } catch (e) {
        setShowSystemErrorMessage(true);
      }
    })();
  }, [name]);

  return (
    <Field
      validate={(country) =>
        validateCountry(
          country,
          nonSupportedCountries,
          disableAddressValidation
        )
      }
      name={name}
    >
      {({ form, field }) => {
        const [objectName, fieldName] = name.split('.');
        const fieldRequiredError =
          form.errors?.[objectName]?.[fieldName] ===
          validationMessages.country.fieldRequired;
        return (
          <>
            <Autocomplete
              {...field}
              options={countries}
              trackingId={trackingId}
              label={label}
              defaultValue={_find(countries, { value: field.value || country })}
              renderOption={(country) =>
                country.value ? (
                  <React.Fragment>
                    <span>{countryToFlag(country.value)}</span>
                    {country.label} ({country.value})
                  </React.Fragment>
                ) : (
                  <Box width="100%" padding={0}>
                    <Divider />
                  </Box>
                )
              }
              getOptionDisabled={(option) => option.divider}
              error={fieldRequiredError}
              helperText={
                fieldRequiredError && form.errors?.[objectName]?.[fieldName]
              }
            />
            {form.errors?.[objectName]?.[fieldName] && !fieldRequiredError && (
              <Alert
                severity={'info'}
                message={form.errors?.[objectName]?.[fieldName]}
              />
            )}
          </>
        );
      }}
    </Field>
  );
}

export default withStore(CountrySelect);

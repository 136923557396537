import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormHelperText, {
  FormHelperTextProps,
} from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      '&.Mui-error': {
        color: `${theme.fox.colors.red.s500.hex}`,
      },
    },
  };
});

const FoxFormHelperText: FC<FormHelperTextProps> = (
  props: FormHelperTextProps
) => {
  const classes = useStyles(props);

  return (
    <FormHelperText {...props} className={classes.root}>
      {props.children}
    </FormHelperText>
  );
};

export default FoxFormHelperText;

export const ukBusinessNonSupportCountry = [
  {
    label: 'Albania',
    value: 'AL',
  },
  {
    label: 'Algeria',
    value: 'DZ',
  },
  {
    label: 'Argentina',
    value: 'AR',
  },
  {
    label: 'Armenia',
    value: 'AM',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    label: 'Bahrain',
    value: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    label: 'Barbados',
    value: 'BB',
  },
  {
    label: 'Benin',
    value: 'BJ',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    label: 'Brazil',
    value: 'BR',
  },
  {
    label: 'Cameroon',
    value: 'CM',
  },
  {
    label: 'Chile',
    value: 'CL',
  },
  {
    label: 'China',
    value: 'CN',
  },
  {
    label: 'Ecuador',
    value: 'EC',
  },
  {
    label: 'Egypt',
    value: 'EG',
  },
  {
    label: 'Estonia',
    value: 'EE',
  },
  {
    label: 'India',
    value: 'IN',
  },
  {
    label: 'Indonesia',
    value: 'ID',
  },
  {
    label: 'Israel',
    value: 'IL',
  },
  {
    label: 'Jamaica',
    value: 'JM',
  },
  {
    label: 'Jordan',
    value: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'KE',
  },
  {
    label: 'Latvia',
    value: 'LV',
  },
  {
    label: 'Lithuania',
    value: 'LT',
  },
  {
    label: 'Madagascar',
    value: 'MG',
  },
  {
    label: 'Malaysia',
    value: 'MY',
  },
  {
    label: 'Mali',
    value: 'ML',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'Moldova',
    value: 'MD',
  },
  {
    label: 'Morocco',
    value: 'MA',
  },
  {
    label: 'Nepal',
    value: 'NP',
  },
  {
    label: 'Oman',
    value: 'OM',
  },
  {
    label: 'Pakistan',
    value: 'PK',
  },
  {
    label: 'Palestinian Territory, Occupied',
    value: 'PS',
  },
  {
    label: 'Peru',
    value: 'PE',
  },
  {
    label: 'Philippines',
    value: 'PH',
  },
  {
    label: 'Qatar',
    value: 'QA',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    label: 'Senegal',
    value: 'SN',
  },
  {
    label: 'Serbia',
    value: 'RS',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
  {
    label: 'Slovakia',
    value: 'SK',
  },
  {
    label: 'South Africa',
    value: 'ZA',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    label: 'Tanzania',
    value: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'TH',
  },
  {
    label: 'Tunisia',
    value: 'TN',
  },
  {
    label: 'Turkey',
    value: 'TR',
  },
  {
    label: 'Uruguay',
    value: 'UY',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'VG',
  },
  {
    label: 'Zambia',
    value: 'ZM',
  },
];

import { LegalEntities } from 'src/types';

export const MARKETPLACE_TERMS =
  'to the sharing of my personal data with a marketplace I receive funds from to assist with overseeing the marketplace or as required by applicable laws.';

const userAgreementConfig = (
  locale: string
): { showCheckbox: boolean; checked: boolean } => {
  switch (locale) {
    case LegalEntities.HK:
    case LegalEntities.GB:
    case LegalEntities.IE:
    case LegalEntities.AU:
    case LegalEntities.CA:
    case LegalEntities.NZ:
    case LegalEntities.SG:
    case LegalEntities.US:
    default:
      return {
        showCheckbox: false,
        checked: true,
      };
  }
};

export default userAgreementConfig;

import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.fox.colors.neutral.s800.hex,
    border: `1px solid ${theme.fox.colors.neutral.s800.hex}`,
    borderRadius: '2px',
    height: '24px',
    width: '24px',
  },
}));

const TickIcon: FC = () => {
  const classes = useStyles({});
  return (
    <svg
      href="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.root}
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g
          transform="translate(-194 -523)"
          stroke="#fff"
          fillRule="nonzero"
          strokeWidth="2"
        >
          <g transform="translate(194 523)">
            <g>
              <g>
                <g>
                  <polyline points="7 13 10 16 17 9" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const CheckedIcon: FC = () => <TickIcon />;

export default CheckedIcon;

import React, { FormEvent, FocusEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import FormHelperText from '../FormHelperText';
import { CheckboxIcon, CheckedIcon } from '../Icons/Checkbox/Checkbox';
import { TrackingProps } from '../../Types';

interface StyleProps {
  hasError?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
  return {
    root: (props) => ({
      '& .MuiBox-root': {
        borderColor: props.hasError
          ? theme.fox.colors.red.s500.hex
          : theme.fox.colors.neutral.s500.hex,
      },
      '&:hover': {
        '& .MuiBox-root': {
          border: `2px solid ${theme.fox.colors.neutral.s800.hex}`,
        },
      },
    }),
  };
});

interface Props {
  checked?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  name?: string;
  label?: string | JSX.Element;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onChange?(event: FormEvent<HTMLInputElement>): void;
  trackingId?: string;
}

const CheckboxField: React.FunctionComponent<Props> = (
  props: Props & TrackingProps
) => {
  const hasError = props.error;
  const classes = useStyles({ hasError });

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            className={classes.root}
            id={props.id}
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            onBlur={props.onBlur}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckedIcon />}
            data-tracking-id={props.trackingId}
          />
        }
        label={props.label}
      />
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}
    </>
  );
};

export default CheckboxField;

import React from 'react';
import flatten from 'flat';
import { scrollToTop } from './scrollUtil';
import { FormikErrors } from 'formik';

interface IFormik {
  submitCount: number;
  isSubmitting: boolean;
  isValid: boolean;
}
interface ISubmitValidationErrorHandlerProps {
  callback: (fn: IFormik) => void;
  formik: IFormik;
}

export const SubmitValidationErrorHandler = (
  props: ISubmitValidationErrorHandlerProps
): null => {
  const { callback, formik } = props;

  const effect = (): void => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      callback(formik);
    }
  };
  React.useEffect(effect, [formik.submitCount, formik.isSubmitting]);

  return null;
};

export const scrollToFieldError = (
  fields: string[],
  errors: FormikErrors<{}>
): void => {
  const flatErrors = Object.keys(flatten(errors));
  let scrollEl;

  for (let i = 0; i < fields.length; i++) {
    if (typeof flatErrors.find((val) => val === fields[i]) !== 'undefined') {
      scrollEl = fields[i];
      break;
    }
  }

  if (scrollEl) {
    scrollToTop(`[name=${scrollEl.replace('.', '\\.')}]`);
  }
};

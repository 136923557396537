import React, { SyntheticEvent, FC, Fragment } from 'react';
import CheckboxField from '@fox/components/CheckboxField';
import { Field, FormikErrors, FormikTouched, connect } from 'formik';
import Typography from '@fox/components/Typography';
import Link from '@material-ui/core/Link';

interface Props {
  value: boolean;
  name: string;
  touched: FormikTouched<{}>;
  errors: FormikErrors<{}>;
  onChange?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  trackingId: string;
  fieldProps?: {
    showCheckbox?: boolean;
    checkboxTexts: CheckboxTexts[];
  };
}

interface CheckboxTexts {
  text: string | JSX.Element;
  link?: string;
  linkText?: string | JSX.Element;
}
[];

interface TextSectionProps {
  checkboxTexts: CheckboxTexts[];
  trackingId: string;
}

const TextSection = ({
  checkboxTexts,
  trackingId,
}: TextSectionProps): JSX.Element => {
  return (
    <div data-tracking-id={trackingId}>
      <Typography variant="caption" whiteSpace="pre-line">
        {checkboxTexts.map((checkboxText, index) => (
          <Fragment key={`${trackingId}-checkboxText-${index}`}>
            {typeof checkboxText.text === 'string'
              ? `${checkboxText.text} `
              : checkboxText.text}
            {checkboxText.link && (
              <Link href={checkboxText.link} target="_blank">
                <u>
                  <b>{checkboxText.linkText}</b>
                </u>
              </Link>
            )}
          </Fragment>
        ))}
      </Typography>
    </div>
  );
};

const Checkbox: FC<Props> = ({
  name,
  touched,
  errors,
  value,
  onBlur,
  onChange,
  trackingId,
  fieldProps: { showCheckbox, checkboxTexts },
}: Props) => {
  return (
    <Field id={name} name={name}>
      {() => {
        return showCheckbox ? (
          <CheckboxField
            name={name}
            error={Boolean(touched[name] && errors[name])}
            helperText={touched[name] && errors[name]}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            label={
              <TextSection
                checkboxTexts={checkboxTexts}
                trackingId={trackingId}
              />
            }
          />
        ) : (
          <TextSection checkboxTexts={checkboxTexts} trackingId={trackingId} />
        );
      }}
    </Field>
  );
};

Checkbox.displayName = 'Checkbox';

export default connect(Checkbox);
